import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {batch, useDispatch, useSelector} from 'react-redux'

import {PageNames} from '../../../../common/constants'
import {FilterDefinition} from '../../../../common/types'
import SupplierNameFilterInvoices from '../../../../Molecules/SupplierFilter/SupplierFilterInvoice'
import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {CustomerSimpleLookup} from '../../../../Organisms/Customers/CustomerSimpleLookup'
import {useFeaturesState} from '../../../../Organisms/Features'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'
import {useFilters} from '../../../../Organisms/Filters/useFilters'
import {setFilter} from '../../../../Organisms/Invoices/Invoices.action'
import {CustomersPayers, selectCustomersPayers} from '../../../../Organisms/Payers'
import {
  removeGlobalProjectFilter,
  removeGlobalSiteFilter
} from '../../../../Organisms/Projects/Projects.action'
import ProjectsSitesFilter from '../../../../Organisms/Projects/ProjectsSitesFilter'
import {Site, SitesFilter} from '../../../../Organisms/SitesTypeahead'
import {usePermissions} from '../../../../Permissions'
import {AppState} from '../../../../Root.store'
import {removeOpenInvoicesFilter, removeSurchargesOnlyFilter} from '../Invoice.filters'

import DateFilter from './DateFilter'
import LookupFilter from './LookupFilter'
import {OpenInvoicesFilter} from './OpenInvoicesFilter'
import {PaymentStatusFilter} from './PaymentStatusFilter'
import {SurchargesOnlyFilter} from './SurchargesOnlyFilter'

export interface FilterBarErrorInvoice {
  key: string
  label: string
}

interface Props {
  errors?: FilterBarErrorInvoice[]
  customerId: string
  payerId?: string
  site?: Site
  setSite: React.Dispatch<React.SetStateAction<Site | undefined>>
  xs?: boolean
  lookupAnalyticsId?: string
  userId?: string
  // mobile part
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  numberOfItems?: number
  apply: () => void
  onSearch?: ({entity, value, label}: {entity: any; value: any; label: any}) => void
}

// eslint-disable-next-line complexity
const Filters: React.FC<Props> = ({
  customerId,
  payerId,
  site,
  setSite,
  xs = false,
  lookupAnalyticsId,
  userId,
  isVisible,
  setIsVisible,
  numberOfItems,
  apply,
  onSearch,
  ...props
}) => {
  const {getFeature} = useFeaturesState()
  const {t} = useTranslation()
  const {displayCustomerSimpleLookup} = usePermissions()
  const dispatch = useDispatch()
  const {errors} = props
  const isLookupError = errors?.some((err) => err.key === 'noLookup')
  const customerSearchByNameEnabled = getFeature('CustomersByName')
  const customerPayers: CustomersPayers[] = useSelector<AppState, CustomersPayers[]>((state) =>
    selectCustomersPayers(state)
  )

  const canShowProjectSitesFilter =
    !getFeature('DestinationSearchByName') && getFeature('ProjectSitesFilter')
  const canShowDestinationSearchByName = getFeature('DestinationSearchByName')
  const canShowOpenInvoicesFilter =
    !getFeature('PaymentStatusFilter') && getFeature('OpenInvoicesFilter')
  const canShowPaymentStatusFilter = getFeature('PaymentStatusFilter')
  const canShowSupplierFilter = getFeature('ShowSupplierFilter')
  const canShowSurchargesOnlyFilter = getFeature('SurchargeServiceOnlyFilter')

  const initialFiltersDefinition: FilterDefinition[] = useMemo(() => {
    const filters: FilterDefinition[] = [
      {
        name: 'account-filter',
        label: t('filterMenu.account'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'date-filter',
        label: t('filterMenu.date'),
        isActive: true,
        isMandatory: true
      }
    ]

    if (canShowProjectSitesFilter) {
      filters.push({
        name: 'projects-sites-filter',
        label: t('filterMenu.projectsSites'),
        isActive: true
      })
    }

    if (canShowDestinationSearchByName) {
      filters.push({
        name: 'sites-filter',
        label: t('filterMenu.sites'),
        isActive: true
      })
    }
    if (canShowOpenInvoicesFilter) {
      filters.push({
        name: 'open-invoices-filter',
        label: t('filterMenu.openInvoices'),
        isActive: true
      })
    }
    if (canShowPaymentStatusFilter) {
      filters.push({
        name: 'payment-status-filter',
        label: t('filterMenu.paymentStatus'),
        isActive: true
      })
    }
    if (canShowSupplierFilter) {
      filters.push({
        name: 'supplier-name-filter',
        label: t('filterMenu.supplierName'),
        isActive: true
      })
    }
    if (canShowSurchargesOnlyFilter) {
      filters.push({
        name: 'surcharges-only-filter',
        label: t('filterMenu.surchargesOnly'),
        isActive: true
      })
    }

    return filters
  }, [])

  const handleOnResetFilter = (filter: FilterDefinition) => {
    switch (filter.name) {
      case 'projects-sites-filter': {
        batch(() => {
          dispatch(removeGlobalProjectFilter())
          dispatch(removeGlobalSiteFilter())
        })
        break
      }

      case 'sites-filter': {
        setSite(undefined)
        break
      }

      case 'open-invoices-filter': {
        dispatch(removeOpenInvoicesFilter())
        break
      }

      case 'payment-status-filter': {
        dispatch(removeOpenInvoicesFilter())
        break
      }

      case 'supplier-name-filter': {
        dispatch(setFilter('orgUnitIds', undefined))
        dispatch(setFilter('supplierFilterNames', undefined))
        break
      }

      case 'surcharges-only-filter': {
        dispatch(removeSurchargesOnlyFilter())
        break
      }
    }
  }

  const {filtersDefinition, handleOnFiltersChange, getFilterValue} = useFilters(
    'finance',
    initialFiltersDefinition,
    handleOnResetFilter
  )

  return (
    <GenericFilterBar
      data-test-id="finance-filters"
      filters={filtersDefinition}
      lookupFilter={
        <LookupFilter
          hasError={isLookupError}
          payerId={payerId}
          customerId={customerId}
          analyticsId={lookupAnalyticsId}
          userId={userId}
        />
      }
      onFiltersChange={handleOnFiltersChange}
      toolbarProps={{notEndLast: true}}
      xs={xs}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      numberOfItems={numberOfItems}
      onSearch={onSearch}
      apply={apply}
    >
      {getFilterValue('account-filter') &&
        (displayCustomerSimpleLookup ? (
          <CustomerSimpleLookup
            data-test-id="invoices-filters-lookup"
            customerSearchByName={customerSearchByNameEnabled}
            page={PageNames.FINANCE_PAGE}
          />
        ) : customerPayers.length !== 1 ? (
          <CustomersFilter onLight page={PageNames.FINANCE_PAGE} />
        ) : null)}
      {getFilterValue('date-filter') && <DateFilter />}
      {getFilterValue('projects-sites-filter') && (
        <ProjectsSitesFilter onLight page={PageNames.FINANCE_PAGE} />
      )}
      {getFilterValue('sites-filter') && (
        <SitesFilter
          customerId={customerId}
          limit={100}
          setSite={setSite}
          site={site}
          page={PageNames.FINANCE_PAGE}
        />
      )}
      {getFilterValue('open-invoices-filter') && <OpenInvoicesFilter onLight={xs} />}
      {getFilterValue('payment-status-filter') && <PaymentStatusFilter />}
      {getFilterValue('supplier-name-filter') && (
        <SupplierNameFilterInvoices customerId={customerId} />
      )}
      {getFilterValue('surcharges-only-filter') && <SurchargesOnlyFilter onLight={xs} />}
    </GenericFilterBar>
  )
}

export default Filters
