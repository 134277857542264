import {Typography} from '@hconnect/uikit'
import {Box, Switch} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {PageNames} from '../../../../common/constants'
import {AppState} from '../../../../Root.store'
import {filterType, useTrackFilterEvents} from '../../../../TrackEvents/hubFilterEvents'
import {
  removeSurchargesOnlyFilter,
  selectSurchargesOnlyFilter,
  setSurchargesOnlyFilter
} from '../Invoice.filters'

export const SurchargesOnlyFilter: React.FC<{onLight: boolean}> = ({onLight}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const {trackFilterEvents} = useTrackFilterEvents()

  const includesServicesOrSurcharges = useSelector<AppState, boolean>((state) =>
    selectSurchargesOnlyFilter(state)
  )

  const toggleSurchargesOrServicesOnly = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    const filterEventData = {
      filterType: filterType.SURCHARGES,
      page: PageNames.FINANCE_PAGE,
      checked,
      cleared: !checked
    }
    trackFilterEvents(filterEventData)

    checked
      ? dispatch(setSurchargesOnlyFilter({includesServicesOrSurcharges: true}))
      : dispatch(removeSurchargesOnlyFilter())
  }

  return (
    <Box display="flex" pl={1} alignItems="center" gap={2}>
      <Switch
        data-test-id="invoices-servicesOrSurcharges-filter"
        aria-label="Surcharges and services switch"
        size="medium"
        checked={includesServicesOrSurcharges}
        onChange={toggleSurchargesOrServicesOnly}
      />
      <Typography variant="body1" color={onLight ? 'textPrimary' : 'textSecondary'}>
        {t('invoice.servicesOrSurcharges.filterLabel')}
      </Typography>
    </Box>
  )
}
