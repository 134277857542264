import {useQueries} from '@tanstack/react-query'
import {AxiosResponse} from 'axios'

import {api} from '../../../../App.global'
import {Delivery} from '../Delivery.types'
import {BreakdownLineItem} from '../Invoice.types'

type DeliveryIdWithRelatedLineItem = {
  deliveryId: string
  relatedLineItem: string
  includePumpService: boolean
}

const fetchSurchargeDeliveries = async ({
  deliveryId,
  relatedLineItem,
  includePumpService
}: DeliveryIdWithRelatedLineItem) => {
  const res: AxiosResponse<Delivery> = await api.get(
    `/deliveries/${deliveryId}${includePumpService ? '?includePumpService=true' : ''}`
  )
  return {delivery: res.data, relatedLineItem}
}

export interface UseSurchargeDeliveriesType {
  surcharges?: BreakdownLineItem[]
  includePumpService: boolean
}
export const useSurchargeDeliveries = ({
  surcharges,
  includePumpService
}: UseSurchargeDeliveriesType) => {
  const arr =
    surcharges?.reduce<DeliveryIdWithRelatedLineItem[]>((agg, curr) => {
      const deliveryId = curr?.links?.find((link) => link.rel === 'deliveries')?.href?.split('/')[1]
      if (deliveryId) {
        agg.push({deliveryId, relatedLineItem: curr.relatedLineItem, includePumpService})
      }
      return agg
    }, []) || []

  const surchargeDeliveriesResults = useQueries({
    queries: arr.map((deliveryWithRelatedLineItem) => ({
      queryKey: [
        'surchargeDeliveries',
        {
          deliveryId: deliveryWithRelatedLineItem.deliveryId,
          relatedLineItem: deliveryWithRelatedLineItem.relatedLineItem,
          includePumpService: includePumpService
        }
      ],
      queryFn: () => fetchSurchargeDeliveries(deliveryWithRelatedLineItem),
      enabled: Boolean(surcharges?.length)
    }))
  })

  return {
    data: surchargeDeliveriesResults.map((surchargeDelivery) => surchargeDelivery.data),
    isFetching: surchargeDeliveriesResults.some(
      (surchargeDelivery) => surchargeDelivery.isFetching
    ),
    isError: surchargeDeliveriesResults.some((surchargeDelivery) => surchargeDelivery.isError)
  }
}
