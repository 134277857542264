import {TextButton} from '@hconnect/uikit'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {Box, Card, CardContent, Grid} from '@mui/material'
import classNames from 'classnames'
import {TFunction} from 'i18next'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {makeStyles} from 'tss-react/mui'

import {FieldValue} from '../../../../../Molecules/FieldDetails'
import {DeliveryTicketNumber, DeliveryVehicle} from '../../../../Order/components/DeliveryFields'
import {QuantityUomToSymbolComponent} from '../../../../Order/Order.components'
import {DeliveriesWithLineItems, Delivery, DeliveryWithLineItem} from '../../Delivery.types'
import {AsideToggleTypes, FinanceAnalytics} from '../../Invoice.types'

import {
  DeliveryMaterialDescription,
  DeliveryPONumber,
  DeliveryTransport
} from './DeliveryLineItemFields'

const useStyles = makeStyles()(() => ({
  card: {
    width: '100%',
    marginBottom: 8,
    paddingTop: 2,
    paddingBottom: 2
  },
  linearOverlay: {
    maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)'
  }
}))

interface DeliveryLineItemCardType {
  delivery: DeliveryWithLineItem
  viewAll: boolean
  toggles: AsideToggleTypes
  analytics?: FinanceAnalytics
  customerId: string
  t: TFunction
  numberOfDeliveries: number
}

export const DeliveryLineItemCard: React.FC<DeliveryLineItemCardType> = ({
  delivery,
  viewAll,
  toggles,
  customerId,
  analytics,
  t
}) => {
  const {classes} = useStyles()

  const modeOfTransportLabel =
    (delivery as Delivery).modeOfTransport !== undefined
      ? (delivery as Delivery).modeOfTransport
      : 'others'

  return (
    <Card
      variant="outlined"
      className={classNames([
        classes.card,
        {
          [classes.linearOverlay]: !viewAll
        }
      ])}
      style={{backgroundColor: '#f7f7f7'}}
    >
      <CardContent style={{paddingBottom: 16}}>
        <Box display="flex" flexDirection="column" alignItems="flex-start">
          <Grid container>
            <Grid item xs={6}>
              <Box data-test-id="ticket-number">
                <FieldValue
                  label={t('order.expand.deliveryNumber')}
                  dataTestId="ticket-number-field"
                  value={
                    <DeliveryTicketNumber
                      delivery={delivery as Delivery}
                      toggles={toggles as any}
                      customerId={customerId}
                      analytics={analytics}
                    />
                  }
                  gutter
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box data-test-id="ticket-ponumber">
                <FieldValue
                  label={t('invoice.expand.orderPoNumber')}
                  dataTestId="ticket-po-number"
                  value={<DeliveryPONumber delivery={delivery} t={t} />}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box data-test-id="ticket-material">
                <FieldValue
                  dataTestId="ticket-material-field"
                  label={t('invoice.expand.material')}
                  spaceAfterLabel={delivery?.isCarbonSavingProduct ? true : false}
                  value={<DeliveryMaterialDescription delivery={delivery} t={t} />}
                  gutter
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container data-test-id="delivery-vehicle-collected">
            <Grid item xs={12}>
              <Box data-test-id="quantity">
                <FieldValue
                  label={t('invoice.expand.quantity')}
                  gutter
                  value={
                    <Box>
                      {(delivery as Delivery).loadQuantity}
                      {(delivery as Delivery).loadQuantityUom ? (
                        <>
                          {' '}
                          <QuantityUomToSymbolComponent
                            uom={(delivery as Delivery).loadQuantityUom || ''}
                          />
                        </>
                      ) : null}
                    </Box>
                  }
                  dataTestId="loadQuantity-value"
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FieldValue
                label={t(`order.expand.modeoftransport.${modeOfTransportLabel}`)}
                dataTestId="vehicle"
                value={
                  <DeliveryVehicle
                    delivery={delivery as Delivery}
                    customerId={customerId}
                    toggles={toggles as any}
                    analytics={analytics}
                  />
                }
              />
            </Grid>
            <Grid item md={6} xs={6} sm={6}>
              <FieldValue
                label={t('invoice.expand.transport')}
                dataTestId="delivery-pickup"
                value={<DeliveryTransport delivery={delivery} t={t} />}
              />
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  )
}

interface CardListType {
  deliveriesWithLineItems: DeliveriesWithLineItems
  viewAll?: boolean
  toggles: AsideToggleTypes
  analytics?: FinanceAnalytics
  customerId: string
  t: TFunction
}
export const DeliveriesLineItemCardList: React.FC<CardListType> = ({
  deliveriesWithLineItems,
  viewAll = false,
  toggles,
  customerId,
  analytics,
  t
}) => {
  return (
    <Box data-test-id="deliveries-list" my={1.75}>
      {deliveriesWithLineItems.map((delivery) => (
        <DeliveryLineItemCard
          key={(delivery as Delivery).deliveryId}
          delivery={delivery}
          viewAll={viewAll}
          toggles={toggles}
          customerId={customerId}
          analytics={analytics}
          numberOfDeliveries={deliveriesWithLineItems.length}
          t={t}
        />
      ))}
    </Box>
  )
}

interface ListType {
  deliveriesWithLineItems: DeliveriesWithLineItems
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  error?: Error | unknown
  loading?: boolean
  toggles: AsideToggleTypes
  analytics?: FinanceAnalytics
  customerId: string
}

export const DeliveriesLineItemList: React.FC<ListType> = ({
  deliveriesWithLineItems,
  error,
  loading,
  toggles,
  analytics,
  customerId
}) => {
  const {t} = useTranslation()
  const [viewAll, setViewAll] = useState<boolean>(deliveriesWithLineItems?.length === 1)
  useEffect(() => setViewAll(deliveriesWithLineItems?.length === 1), [deliveriesWithLineItems])

  if (error) {
    return <Box>{t('error.server')}</Box>
  }
  return (
    <Box display="flex" flexDirection="column" pt={1}>
      <Grid container>
        <Grid item xs={6}>
          <FieldValue
            dataTestId="deliveries-list-nr-items"
            label={t('order.orderDetails.deliveryItems')}
            value={t('order.orderDetails.numberOfDeliveries', {
              numberOfDeliveries: deliveriesWithLineItems.length
            })}
          />
        </Grid>
        <Grid item xs={6}>
          {deliveriesWithLineItems.length > 1 && (
            <TextButton
              endIcon={<ArrowDropDownIcon />}
              data-test-id="deliveries-view-all"
              color="secondary"
              variant="text"
              onClick={() => setViewAll(!viewAll)}
              disabled={loading}
            >
              {t('order.orderDetails.viewAllDeliveries', {
                numberOfDeliveries: deliveriesWithLineItems.length
              })}
            </TextButton>
          )}
        </Grid>
      </Grid>
      <DeliveriesLineItemCardList
        t={t}
        toggles={toggles}
        analytics={analytics}
        deliveriesWithLineItems={
          viewAll ? deliveriesWithLineItems : deliveriesWithLineItems.slice(0, 1)
        }
        viewAll={viewAll}
        customerId={customerId}
      />
    </Box>
  )
}
