import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PageNames} from '../../../../common/constants'
import {FilterDefinition} from '../../../../common/types'
import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'
import {useFilters} from '../../../../Organisms/Filters/useFilters'

import {Year, YearFilter} from './YearFilter'

export interface FilterBarErrorStatements {
  key: string
  label: string
}

export interface StatementsFilter {
  year?: string
  payerId?: string
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
}

interface FilterType {
  errors?: FilterBarErrorStatements[]
  otherProps?: React.ReactPropTypes
  xs?: boolean
  selectedYear?: Year
  onDropdownChange: (year: {label: string; id: number}) => void
  // mobile part
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  numberOfItems?: number
  apply: () => void
}

export const Filters: React.FC<FilterType> = ({
  xs,
  otherProps,
  selectedYear,
  onDropdownChange,
  isVisible,
  setIsVisible,
  numberOfItems,
  apply
}) => {
  const {t} = useTranslation()

  const initialFiltersDefinition: FilterDefinition[] = useMemo(
    () => [
      {
        name: 'customer-filter',
        label: t('filterMenu.customer'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'years-filter',
        label: t('filterMenu.years'),
        isActive: true,
        isMandatory: true
      }
    ],
    []
  )

  const {filtersDefinition, handleOnFiltersChange, getFilterValue} = useFilters(
    'statements',
    initialFiltersDefinition
  )

  return (
    <GenericFilterBar
      filters={filtersDefinition}
      data-test-id="statements-filters"
      toolbarProps={{notEndLast: true}}
      onFiltersChange={handleOnFiltersChange}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      numberOfItems={numberOfItems}
      xs={xs}
      apply={apply}
      {...otherProps}
    >
      {getFilterValue('customer-filter') && <CustomersFilter onLight page={PageNames.STATEMENTS} />}
      {getFilterValue('years-filter') && (
        <YearFilter year={selectedYear} onDropdownChange={onDropdownChange} />
      )}
    </GenericFilterBar>
  )
}
