import {
  isoDateFormatter,
  isoUtcStartPrevYear,
  ResponsiveTable,
  Typography,
  useBreakPoints
} from '@hconnect/uikit'
import {Box, Paper} from '@mui/material'
import React, {useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from 'tss-react/mui'

import {useQueryParamState} from '../../../Hooks/useQueryParamState'
import {ResultsForMessage} from '../../../Molecules/ResultsForMessage'
import {SMFilter} from '../../../Molecules/SMFilter/SMFilter'
import {selectCustomers} from '../../../Organisms/Customers'
import {CustomerStateType} from '../../../Organisms/Customers/Action.types'
import {downloadDocument as downloadStatements} from '../../../Organisms/Documents/Documents.actions'
import {PayersStateType} from '../../../Organisms/Payers'
import {selectPayers} from '../../../Organisms/Payers/Payers.selectors'
import {AppState} from '../../../Root.store'

import {StatementsCardList} from './components'
import {FilterBarErrorStatements, Filters, StatementsFilter} from './Filters'
import {CurrentYear, Year} from './Filters/YearFilter'
import {StatementsColumns} from './Statements.columns'
import {useStatements} from './useHooks'
import {filterType, useTrackFilterEvents} from '../../../TrackEvents/hubFilterEvents'
import {PageNames} from '../../../common/constants'
import {ResponsiveInnerHeightWithFilters } from '../../../Molecules/Responsive.utils'

export const useStyles = makeStyles()(() => ({
  gridItem: {
    overflowY: 'auto',
    overscrollBehavior: 'contain',
    '&::-webkit-scrollbar': {
      width: '0.5em'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }
}))

interface StatementsComponentType {
  analytics: {
    userId: string | undefined
    userMail: string | null | undefined
    userCountry: string | null | undefined
    userRoles: any[] | undefined
    userIsInternal: boolean | undefined
    customerId: string | undefined
    customerName: string | undefined
  }
}

// eslint-disable-next-line complexity
export const StatementsComponentPage: React.FC<StatementsComponentType> = ({analytics}) => {
  const {
    t,
    i18n: {language}
  } = useTranslation()
  const dispatch = useDispatch()
  const screenSize = useBreakPoints()
  const {classes} = useStyles()
  const now = new Date()
  const [smFilterVisible, setSmFilterVisible] = React.useState<boolean>(false)
  const [selectedYear, setSelectedYear] = React.useState<Year>(CurrentYear)
  const [startDate, setStartDate] = React.useState<string>(isoUtcStartPrevYear(now))
  const [endDate, setEndDate] = React.useState<string>(isoDateFormatter(now))
  const [filter, setFilter] = useQueryParamState<StatementsFilter>(
    {
      year: selectedYear?.label,
      payerId: undefined,
      skip: 0,
      limit: 10,
      sortedByKey: 'date',
      sortedByDirection: 'desc'
    },
    true
  )
  const setSortKey = (sortKey: string) => {
    const asc = !(filter.sortedByKey === sortKey && filter.sortedByDirection === 'asc')
    setFilter({
      ...filter,
      sortedByKey: sortKey,
      sortedByDirection: asc ? 'asc' : 'desc'
    })
  }
  const payersState = useSelector<AppState, PayersStateType>((state) => selectPayers(state))
  const {selectedPayer, isFetching: isPayersFetching} = payersState

  const {data, isFetching, isError} = useStatements({
    payerId: selectedPayer?.payerId,
    startDate,
    endDate,
    sortedBy: `${filter.sortedByKey} ${filter.sortedByDirection}`,
    enabled: true
  })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {total, statements} = data || {}
  const customers = useSelector<AppState, CustomerStateType>((state: AppState) =>
    selectCustomers(state)
  )
  const {trackFilterEvents} = useTrackFilterEvents()

  const {isFetching: isCustomersFetching} = customers

  const smallScreen = ['xs', 'sm'].includes(screenSize)
  const items = selectedPayer ? statements : []

  const getEmptyMessage = (loading: boolean) => (loading ? t('loading') : t('noData'))

  const downloadDocuments = (jobId: string, documentId: string, fileName: string) =>
    dispatch(downloadStatements(jobId, documentId, fileName))

  const columnConfig = StatementsColumns({t, language, downloadDocuments, analytics})

  const filterBarErrors: FilterBarErrorStatements[] = []

  const onYearFilterChange = (year: Year) => {
    setSelectedYear(year)
    trackFilterEvents({
      filterType: filterType.YEAR,
      page: PageNames.STATEMENTS,
      year: year.id,
      cleared: false
    })
  }

  if (!isError && !isFetching && items && items.length === 0) {
    filterBarErrors.push({key: 'empty', label: t('filterBar.errorEmpty')})
  }

  const resultsForMessage = [selectedPayer?.payerName]

  const loading = isFetching || isPayersFetching || isCustomersFetching

  useEffect(() => {
    setFilter({
      ...filter,
      payerId: selectedPayer?.payerId
    })
  }, [selectedPayer])

  useEffect(() => {
    if (selectedYear && selectedYear.id > -1) {
      setStartDate(`${selectedYear.id}-01-01`)
      setEndDate(`${selectedYear.id}-12-31`)
    } else if (selectedYear) {
      setStartDate(isoUtcStartPrevYear(now))
      setEndDate(isoDateFormatter(now))
    }
  }, [selectedYear])

  return (
    <>
      <Box data-test-id="statements-filters-part">
        <Filters
          errors={filterBarErrors}
          selectedYear={selectedYear}
          onDropdownChange={onYearFilterChange}
          isVisible={smFilterVisible}
          setIsVisible={setSmFilterVisible}
          xs={smallScreen}
          numberOfItems={items?.length}
          apply={() => setSmFilterVisible(false)}
        />
      </Box>

      <Paper
        elevation={4}
        aria-label="Payment list"
        className={smallScreen ? '' : classes.gridItem}
        style={{paddingBottom: 20, ...(!smallScreen ? {height: '100%', maxHeight: ResponsiveInnerHeightWithFilters, marginTop: 5} : {})}}
      >
        {/* {error ? (
        <ErrorText
          data-test-id="payments-error-message"
          label={t(error.translationKey)}
          showRetry={!!error?.callback}
          onRetry={error.callback}
        />
      ) : null} */}
        {!isError ? (
          <>
            {!loading && !smallScreen ? (
              <ResultsForMessage messageList={resultsForMessage} />
            ) : null}
            {!smallScreen ? (
              <Box px={2} className={classes.gridItem}>
                <ResponsiveTable
                  rows={items || []}
                  columns={columnConfig}
                  onSort={(e, sortKey) => {
                    setSortKey(sortKey)
                  }}
                  sortedBy={filter.sortedByKey}
                  sortingOrder={filter.sortedByDirection}
                  loading={loading}
                  keyField="paymentId"
                  emptyMessage={
                    <Typography variant="caption">{getEmptyMessage(loading)}</Typography>
                  }
                />
              </Box>
            ) : (
              <Box mx={2}>
                <StatementsCardList
                  rows={items || []}
                  loading={loading}
                  keyField="statementId"
                  analytics={analytics}
                  downloadDocuments={downloadDocuments}
                  fullScreen={smallScreen}
                  screenSize={screenSize}
                />
              </Box>
            )}
          </>
        ) : null}
      </Paper>
    </>
  )
}
