import {Moment} from 'moment'
import React, {useMemo} from 'react'
import {useTranslation} from 'react-i18next'

import {PageNames} from '../../../../common/constants'
import {FilterDefinition} from '../../../../common/types'
import CustomersFilter from '../../../../Organisms/Customers/CustomersFilter'
import {GenericFilterBar} from '../../../../Organisms/Filters/GenericFilterBar'
import {useFilters} from '../../../../Organisms/Filters/useFilters'

import {DateFilter} from './DateFilter'

export interface FilterBarErrorPayments {
  key: string
  label: string
}

export interface PaymentsFilter {
  startDate: string
  endDate: string
  payerId?: string
  skip: number
  limit: number
  sortedByKey: string
  sortedByDirection: 'asc' | 'desc'
}

interface FilterType {
  startDate: Moment
  endDate: Moment
  handleDates: (filter: {startDate: Moment | null; endDate: Moment | null}) => void
  errors?: FilterBarErrorPayments[]
  otherProps?: React.ReactPropTypes
  // mobile part
  xs?: boolean
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  numberOfItems?: number
  apply: () => void
}

export const Filters: React.FC<FilterType> = ({
  startDate,
  endDate,
  handleDates,
  errors,
  otherProps,
  xs = false,
  isVisible,
  setIsVisible,
  numberOfItems,
  apply
}) => {
  const {t} = useTranslation()

  const initialFiltersDefinition: FilterDefinition[] = useMemo(() => {
    const filters = [
      {
        name: 'account-filter',
        label: t('filterMenu.account'),
        isActive: true,
        isMandatory: true
      },
      {
        name: 'date-filter',
        label: t('filterMenu.date'),
        isActive: true,
        isMandatory: true
      }
    ]

    return filters
  }, [])

  const {filtersDefinition, handleOnFiltersChange, getFilterValue} = useFilters(
    'transactions',
    initialFiltersDefinition
  )

  return (
    <GenericFilterBar
      data-test-id="transaction-filters"
      filters={filtersDefinition}
      onFiltersChange={handleOnFiltersChange}
      toolbarProps={{notEndLast: true}}
      errors={errors}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      numberOfItems={numberOfItems}
      xs={xs}
      apply={apply}
      {...otherProps}
    >
      {getFilterValue('account-filter') && <CustomersFilter onLight page={PageNames.PAYMENTS} />}
      {getFilterValue('date-filter') && (
        <DateFilter filter={{startDate, endDate}} onDateChange={handleDates} />
      )}
    </GenericFilterBar>
  )
}
