import React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {PageNames} from '../../../../common/constants'
import MultiSelectDropdown from '../../../../Molecules/MultiSelectDropdown'
import {AppState} from '../../../../Root.store'
import {filterType, useTrackFilterEvents} from '../../../../TrackEvents/hubFilterEvents'
import {
  removeOpenInvoicesFilter,
  selectOpenInvoicesFilter,
  setOpenInvoicesFilter
} from '../Invoice.filters'
import {InvoiceStatus} from '../Invoice.types'

export const PaymentStatusFilter = () => {
  const {t} = useTranslation()
  const includesOpenInvoices = useSelector<
    AppState,
    {status: keyof typeof InvoiceStatus} | undefined
  >((state) => selectOpenInvoicesFilter(state))

  const status = [InvoiceStatus.open, InvoiceStatus.closed, InvoiceStatus.partiallyPaid]
  const dispatch = useDispatch()
  const {trackFilterEvents} = useTrackFilterEvents()

  const getPaymentStatusFilters = (selectedList: string[]) => {
    const isListEmptyOrFull = selectedList.length === 0 || selectedList.length === status.length
    const filterEventData = {
      filterType: filterType.PAYMENT_STATUS,
      page: PageNames.FINANCE_PAGE,
      paymentStatus: isListEmptyOrFull ? [] : selectedList,
      cleared: isListEmptyOrFull
    }
    trackFilterEvents(filterEventData)
    isListEmptyOrFull
      ? dispatch(removeOpenInvoicesFilter())
      : dispatch(setOpenInvoicesFilter({status: selectedList as Array<keyof typeof InvoiceStatus>}))
  }

  return (
    <MultiSelectDropdown
      items={status}
      selectedOptionsList={
        Array.isArray(includesOpenInvoices?.status) ? includesOpenInvoices?.status : status
      }
      onClose={getPaymentStatusFilters}
      dataTestId="payment-status-filter"
      multiSelectLabel={t('invoice.paymentStatus')}
      selectAllLabel={t('filterMenu.allStatus')}
      translationItemPrefix="invoice.paymentStatusFilter"
    />
  )
}
