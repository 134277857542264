import {selectCustomerFilterAbstract} from '../../../Organisms/Customers'
import {createSelectFilter} from '../../../Organisms/Filters'
import {
  removeFilter,
  selectInvoices,
  setDefaultFilter,
  setFilter
} from '../../../Organisms/Invoices'
import {selectPayerFilterAbstract} from '../../../Organisms/Payers'
import {selectProjectFilterAbstract} from '../../../Organisms/Projects'
import {AppState} from '../../../Root.store'

import {InvoiceStatus} from './Invoice.types'

/*
Project
  */
export const selectProjectFilter = (state: AppState) =>
  selectProjectFilterAbstract(selectInvoices(state))

/*
Site
 */
const SITEFILTER = 'site'
export const setSiteFilter = ({siteId}: {siteId: string}) => setFilter(SITEFILTER, {siteId})
export const setDefaultSiteFilter = ({siteId}: {siteId: string}) =>
  setDefaultFilter(SITEFILTER, {siteId})
export const removeSiteFilter = () => removeFilter(SITEFILTER)
const selectSiteFilterAbstract = createSelectFilter(SITEFILTER)
export const selectSiteFilter = (state: AppState) => selectSiteFilterAbstract(selectInvoices(state))

/*
Date
 */
const DATEFILTER = 'date'
interface DateFilterType {
  startDate: any
  endDate: any
}
export const setDateFilter = (filter: DateFilterType) => setFilter(DATEFILTER, filter)
export const setDefaultDateFilter = (filter: DateFilterType) => setDefaultFilter(DATEFILTER, filter)
export const removeDateFilter = () => removeFilter(DATEFILTER)
const selectDateFilterAbstract = createSelectFilter(DATEFILTER)
export const selectDateFilter = (state: AppState) => selectDateFilterAbstract(selectInvoices(state))

/*
Customer a.k.a. Accounts
 */
export const selectCustomerInvoiceFilter = (state: AppState) =>
  selectCustomerFilterAbstract(selectInvoices(state))

export const selectPayerInvoiceFilter = (state: AppState) =>
  selectPayerFilterAbstract(selectInvoices(state))

/*
Lookup
 */
const LOOKUPFILTER = 'lookup'
interface LookupFilterType {
  entity: string
  value: string
  label: string
}
export const setLookupFilter = (filter: LookupFilterType) => setFilter(LOOKUPFILTER, filter)
export const removeLookupFilter = () => removeFilter(LOOKUPFILTER)
const selectLookupFilterAbstract = createSelectFilter(LOOKUPFILTER)
export const selectLookupFilter = (state: AppState): LookupFilterType =>
  selectLookupFilterAbstract(selectInvoices(state))

/**
 * Surcharges only filter
 */
const SURCHARGES_ONLY_FILTER = 'surcharges'
interface SurchargesOnlyFilterType {
  includesServicesOrSurcharges: boolean
}
export const setSurchargesOnlyFilter = (filter: SurchargesOnlyFilterType) =>
  setFilter(SURCHARGES_ONLY_FILTER, filter)
export const setDefaultSurchargesOnlyFilter = (filter: SurchargesOnlyFilterType) =>
  setDefaultFilter(SURCHARGES_ONLY_FILTER, filter)
export const removeSurchargesOnlyFilter = () => removeFilter(SURCHARGES_ONLY_FILTER)
const selectSurchargesOnlyFilterAbstract = createSelectFilter(SURCHARGES_ONLY_FILTER)
export const selectSurchargesOnlyFilter = (state: AppState) =>
  selectSurchargesOnlyFilterAbstract(selectInvoices(state))

/**
 * Open invoices filter
 */
const OPEN_INVOICES_FILTER = 'status'
interface OpenInvoicesFilterType {
  status: Array<keyof typeof InvoiceStatus>
}
export const setOpenInvoicesFilter = (filter: OpenInvoicesFilterType) =>
  setFilter(OPEN_INVOICES_FILTER, filter)
export const setDefaultOpenInvoicesFilter = (filter: OpenInvoicesFilterType) =>
  setDefaultFilter(OPEN_INVOICES_FILTER, filter)
export const removeOpenInvoicesFilter = () => removeFilter(OPEN_INVOICES_FILTER)
const selectOpenInvoicesFilterAbstract = createSelectFilter(OPEN_INVOICES_FILTER)
export const selectOpenInvoicesFilter = (state: AppState) =>
  selectOpenInvoicesFilterAbstract(selectInvoices(state))
/*
Reset
 */
export const clearAllFilters = () => (dispatch) => dispatch(removeDateFilter())
