import {DatePicker, lastNinetyDays, lastSixtyDays, lastThirtyDays} from '@hconnect/uikit'
import {Moment} from 'moment'
import React from 'react'
import {useTranslation} from 'react-i18next'

import {useFeaturesState} from '../../../../Organisms/Features'
import { makeStyles } from '@material-ui/core'

interface Dates {
  startDate: Moment | null
  endDate: Moment | null
}

interface DateFilterType {
  filter: {startDate: Moment; endDate: Moment}
  onDateChange: (filter: Dates) => void
}
const useStyle = makeStyles(() => ({
  text: {height: 60}
}))
export const DateFilter: React.FC<DateFilterType> = ({filter, onDateChange, ...props}) => {
  const classes = useStyle()
  const {startDate, endDate} = filter
  const {getFeature} = useFeaturesState()
  const showMaxDayMessage = getFeature('MaxDayRangeMessage')
  const showMax90DateRange = getFeature('MaxDayRange90')
  const showMax31DateRange = getFeature('MaxDayRange31')
  const {t} = useTranslation()
  const getDatePickerQuickSelectionItems = (locale: string) => [
    {
      key: 'last-30-days',
      title: t('payments.datePicker.lastThirtyDays'),
      type: 'range',
      dates: lastThirtyDays(locale)
    },
    {
      key: 'last-60-days',
      title: t('payments.datePicker.lastSixtyDays'),
      type: 'range',
      dates: lastSixtyDays(locale)
    },
    {
      key: 'last-90-days',
      title: t('payments.datePicker.lastNinetyDays'),
      type: 'range',
      dates: lastNinetyDays(locale)
    }
  ]

  return (
    <DatePicker
      data-test-id="payments-filter-datepicker"
      startDate={startDate}
      endDate={endDate}
      hideClearButton
      {...(showMax31DateRange && {
        maxDayRange: 31
      })}
      {...(showMax90DateRange && {
        maxDayRange: 90
      })}
      {...(showMaxDayMessage && {
        maxDayRangeInfoMessage: t('datePicker.maxDayRange')
      })}
      handleDateChange={onDateChange}
      getQuickSelectionItems={getDatePickerQuickSelectionItems}
      textClasses={classes.text}
      {...props}
    />
  )
}
